import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMonth, getYear } from "date-fns";
import { Divider } from "@mui/material";

import classes from "./Calendar.module.scss";
import { CalendarHeader } from "./components/Header/CalendarHeader";
import { takeMonth, takeYear } from "../../../../../utils/calendarUtils";
import { RenderMonth } from "./components/RenderMonth/RenderMonth";
import { RenderYear } from "./components/RenderYear/RenderYear";
import { subtractMonths } from '../../../../../utils/dateUtils';
import { SHOW_MONTH } from "../../../../../utils/constants/commonConstants";
import { Highlight } from "../../../../common/BasicHighlight/BasicHighlight";
import { revenueByMonthSelector } from '../../../../../redux/selectors/revenueSelector';
import { isMobileModeSelector } from "../../../../../redux/selectors/appStatusSelector";
import { GET_REVENUE_BY_MONTH_REQUEST } from '../../../../../redux/actions';
import { longMonthsNames } from '../../../../../utils/constants/periodLists';

interface ICalendar {
  className: string;
  onlyOneMonth: boolean;
  reservationsData: any;
  isLeftSide: boolean;
}

export const calendarLegend = [
  {
    label: "Paid Reservations",
    color: "#00B48D",
    legendType: "circle",
  },
  {
    label: "Owner Blocks",
    color: "#5D6293",
    legendType: "circle",
  },
];

export const Calendar = ({
  className,
  onlyOneMonth,
  reservationsData,
  isLeftSide,
}: ICalendar) => {
  const dispatch = useDispatch();

  const today = new Date();
  // Get the timezone offset in minutes (negative for UTC+ and positive for UTC-)
  // const timezoneOffsetMinutes = todayLocal.getTimezoneOffset();

  // // Calculate hours and minutes offset
  // const offsetHours = Math.floor(Math.abs(timezoneOffsetMinutes) / 60);
  // const offsetMinutes = Math.abs(timezoneOffsetMinutes) % 60;

  // // Adjust the time
  // const today = new Date(todayLocal);
  // if (timezoneOffsetMinutes > 0) {
  //   // Add the offset for UTC-
  //   today.setUTCHours(todayLocal.getUTCHours() + offsetHours);
  //   today.setUTCMinutes(todayLocal.getUTCMinutes() + offsetMinutes);
  // } else {
  //   // Subtract the offset for UTC+
  //   today.setUTCHours(todayLocal.getUTCHours() - offsetHours);
  //   today.setUTCMinutes(todayLocal.getUTCMinutes() - offsetMinutes);
  // } 

// console.log("Local Time:", todayLocal);
// console.log("Adjusted to UTC Time:", today);

  // Display in UTC format
  // console.log('today',today);  
  // console.log(today1.toISOString());
  const [currentDate, setCurrentDate] = useState(today);
  const [mode, setMode] = useState(SHOW_MONTH);
  const isMobileView = useSelector(isMobileModeSelector);

  const previousMonthDate = subtractMonths(currentDate, 1);

  const currentYear = getYear(currentDate);
  const currentMonth = getMonth(currentDate);

  const previousYear = getYear(previousMonthDate as Date);
  const previousMonth = getMonth(previousMonthDate as Date);

  const fromYearData = useSelector(revenueByMonthSelector(String(currentYear)));
  const toYearData = useSelector(revenueByMonthSelector(String(previousYear)));

  // const totalMonthIncome = +fromYearData
  //   ?.find((elem: any) => longMonthsNames[getMonth(new Date(elem.start_date))] === longMonthsNames[currentMonth])
  //   ?.Revenue.toFixed(0) || 0;

  const totalMonthIncome = +fromYearData
    ?.find((elem: any) => {
      const date = new Date(elem.start_date + 'T00:00:00Z');
      return longMonthsNames[(date.getUTCMonth())] === longMonthsNames[currentMonth]
    })
    ?.Revenue.toFixed(0) || 0;

  const yearList = Object.keys(reservationsData).map((year) => ({
    id: year,
    label: year,
  }));
  
  // const previousTotalMonthIncome = +toYearData
  //   ?.find((elem: any) => longMonthsNames[getMonth(new Date(elem.start_date))] === longMonthsNames[previousMonth])
  //   ?.Revenue.toFixed(0) || 0;
  const previousTotalMonthIncome = +toYearData
    ?.find((elem: any) => longMonthsNames[(new Date(elem.start_date).getUTCMonth())] === longMonthsNames[previousMonth])
    ?.Revenue.toFixed(0) || 0;

  const startOfCurrentYear = new Date(getYear(currentDate), 0, 1);

  const data =
    mode === SHOW_MONTH
      ? takeMonth(currentDate)()
      : takeYear(startOfCurrentYear)();

  useEffect(() => {
    if (isMobileView || onlyOneMonth) {
      dispatch({
        type: GET_REVENUE_BY_MONTH_REQUEST,
        payload: { year: currentYear },
      });
    }

    if (!isLeftSide) return;

    dispatch({
      type: GET_REVENUE_BY_MONTH_REQUEST,
      payload: { year: currentYear },
    });

    if (currentYear != previousYear) {
      dispatch({
        type: GET_REVENUE_BY_MONTH_REQUEST,
        payload: { year: previousYear },
      });
    }
  }, [currentYear, previousYear]);

  return (
    <div className={className}>
      <CalendarHeader
        currentDate={currentDate}
        currentMode={mode}
        monthtoYearSwitch={setMode}
        onlyOneMonth={onlyOneMonth}
        setCurrentDate={setCurrentDate}
        yearList={yearList}
        mode={mode}
        setMode={setMode}
      />
      {onlyOneMonth || mode === SHOW_MONTH ? (
        <>
          <RenderMonth
            currentMonth={currentMonth}
            currentYear={currentYear}
            calendarData={data}
            reservationsData={reservationsData}
            small={onlyOneMonth}
            year={false}
            isLeftSide={isLeftSide}
          />
          {(isMobileView || onlyOneMonth) && (
            <>
              <Divider className={classes.divider} flexItem />
              <Highlight
                changeValueColor={true}
                className={classes.netIncomeHighlight}
                label="Net Reservation Income"
                prependSymbol={"$"}
                value={totalMonthIncome}
                previousValue={previousTotalMonthIncome}
              />
            </>
          )}
        </>
      ) : (
        <RenderYear
          currentYear={currentYear}
          calendarData={data}
          reservationsData={reservationsData}
          setCurrentDate={setCurrentDate}
          setMode={setMode}
        />
      )}
    </div>
  );
};
