import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";
import classes from "./ReservationsHighlights.module.scss";
import { RESERVATIONS_HIGHLIGHTS_REQUEST } from "../../../../../redux/actions";
import { isNumber } from "../../../../../utils/commonUtils";
import { L12M, L3060D, N30D, L30D } from '../../../../../utils/constants/filterConstants';
import { PeriodDropdown } from "../../../../common/PeriodDropdown/PeriodDropdown";
import { periodList } from "../../../../../utils/constants/periodLists";
import { Highlight } from "../../../../common/BasicHighlight/BasicHighlight";
import {
  reservationsHighlightsErrorSelector,
  reservationsHighlightsLoadingSelector,
  reservationsHighlightsSelector
} from "../../../../../redux/selectors/reservationsHighlightsSelector";
import { Loader } from "../../../../common/Loader/Loader";
import { isMobileModeSelector } from "../../../../../redux/selectors/appStatusSelector";

export const ReservationsHighlights = () => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState(L30D);
  const previousPeriod = period === N30D ? L12M : L3060D;

  const data = useSelector(reservationsHighlightsSelector(period));
  const previousData = useSelector(reservationsHighlightsSelector(previousPeriod));

  const {
    uniqueReservations,
    guestsHosted,
    averageStay,
    averageBookingWindow,
  } = data || {};

  const {
    uniqueReservations: previousUniqueReservations,
    guestsHosted: previousGuestsHosted,
    averageStay: previousAverageStay,
    averageBookingWindow: previousAverageBookingWindow,
  } = previousData || {};

  const isLoading = useSelector(reservationsHighlightsLoadingSelector);
  const isError = useSelector(reservationsHighlightsErrorSelector);
  const isMobileView = useSelector(isMobileModeSelector);

  useEffect(() => {
    dispatch({
      type: RESERVATIONS_HIGHLIGHTS_REQUEST,
      payload: { param: period }
    });
  }, [dispatch, period]);

  return (
    <div className={classes.reservationsHighlightsWrapper}>
      <>
        <div className={classes.reservationsHighlightsHeader}>
          <h4>Reservations</h4>
          <PeriodDropdown
            className={classes.dropdown}
            period={period}
            periodList={periodList}
            setPeriod={setPeriod}
          />
        </div>
        <Loader isLoading={isLoading} isError={isError} data={data} />
        {data && (
          <div className={classes.highlights}>
            {uniqueReservations && (
              <>
                <Highlight
                  className={classes.highlight}
                  label="Unique Reservations"
                  tooltip={"Unique reservations are individual reservations"}
                  value={uniqueReservations}
                  previousValue={previousUniqueReservations}
                  reverse={isMobileView}
                  showDynamicIndicator={period !== L12M && period !== N30D}
                />
                <Divider flexItem orientation={isMobileView ? "horizontal" : "vertical"} />
              </>
            )}
            {isNumber(guestsHosted?.guestHosted) && (
              <>
                <Highlight
                  className={classes.highlight}
                  label="Guests Hosted"
                  tooltip={
                    "This number indicates the total number of guests you have hosted at this property in the last period"
                  }
                  value={guestsHosted?.guestHosted}
                  previousValue={previousGuestsHosted?.L30_60D_guestHosted}
                  reverse={isMobileView}
                  showDynamicIndicator={period !== L12M && period !== N30D}
                />
                <Divider flexItem orientation={isMobileView ? "horizontal" : "vertical"} />
              </>
            )}
            {isNumber(Number(averageStay?.Average_LOS)) && (
              <>
                <Highlight
                  className={classes.highlight}
                  label="Average Length of Stay"
                  tooltip={
                    "This is the average amount of time a guest/(s) has stayed at your property in a given reservation"
                  }
                  value={averageStay?.Average_LOS}
                  previousValue={previousAverageStay?.L30_60D_Average_LOS}
                  reverse={isMobileView}
                  showDynamicIndicator={period !== L12M && period !== N30D}
                />
                <Divider flexItem orientation={isMobileView ? "horizontal" : "vertical"} />
              </>
            )}
            {isNumber(Number(averageBookingWindow?.TotalAverageBooking)) && (
              <Highlight
                className={classes.highlight}
                label="Average Booking Window"
                tooltip={
                  "This the average period of time between when a reservation is made by the guest and their check-in date"
                }
                value={averageBookingWindow?.TotalAverageBooking}
                previousValue={previousAverageBookingWindow?.L30_60D_TotalAverageBooking}
                reverse={isMobileView}
                showDynamicIndicator={period !== L12M && period !== N30D}
              />
            )}
          </div>
        )}
      </>
    </div>
  );
};
