import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import { OccupancyRateBarLineCharts } from "../../common/OccupancyRateCharts/OccupancyRateBarLineCharts";
import { PerfomanceHighlights } from "./components/PerfomanceHighlights";

export const PerfomancePage = () => {
  return (
    <FullPageContainer>
      <>
        <PerfomanceHighlights/>
        <OccupancyRateBarLineCharts page="performance" bothCharts={false} />
        <p style={{ color: "#424581", fontStyle: "italic", fontSize: "16px", fontWeight: "400"}}>All amounts shown in the reports are in USD.</p>
      </>
    </FullPageContainer>
  );
}
