import { format } from 'date-fns';

const copyDate = (date: Date) => new Date(date.getTime());

export function addMonths(date: Date | null | undefined, months: number) {
  if (date) {
    const copy = copyDate(date);
    copy.setMonth(date.getMonth() + months);

    return copy;
  }

  return null;
}

export function subtractMonths(date: Date | null | undefined, months: number) {
  if (date) {
    const copy = copyDate(date);
    copy.setMonth(date.getMonth() - months);

    return copy;
  }

  return null;
}


export const getDisplayDate = (
  startDate: Date | null | undefined,
  endDate: Date | null | undefined
) => {
  return startDate || endDate
    ? `${startDate ? format(startDate, "yyyy-MM-dd") : ""} / ${
      endDate ? format(endDate, "yyyy-MM-dd") : ""
    }`
    : "";
}
