import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classes from './ReservationsTable.module.scss';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { GET_RESERVATIONS_DATA_REQUEST, RESERVATIONS_INDICATORS_REQUEST, RESERVATIONS_PREVIOUS_INDICATORS_REQUEST } from '../../../../redux/actions';
import {
  allReservations, previousHighlightsSelector,
  previousReservationsSelector, upcomingHighlightsSelector,
  upcomingReservationsSelector,
} from '../../../../redux/selectors/reservationsData';
import { IReservation } from '../../../interfaces';
import { showLabel, toPrettyNumber } from '../../../../utils/commonUtils';
import {
  longMonthsNames,
  shortMonthsNames,
} from '../../../../utils/constants/periodLists';
import { LeftChevron } from '../../../../assets/icons/LeftChevron';
import {
  CalendarReservationsHighlights
} from './components/CalendarReservationsHighlights/CalendarReservationsHighlights';
import { SwitchControl } from '../../../common/SwitchControl/SwitchControl';
import { SelectDates } from './components/SelectDates/SelectDates';
import { filterReservationsByDateRange } from '../../../../utils/dataProcessingUtils';
import { isMobileModeSelector } from '../../../../redux/selectors/appStatusSelector';
import { reservationsIndicatorsSelector } from '../../../../redux/selectors/reservationsIndicatorsSelector';
import { reservationsPreviousIndicatorsSelector } from '../../../../redux/selectors/reservationsPreviousIndicatorsSelector';

export const ReservationsTable = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState('Upcoming');
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const filteredReservations = useRef<any>(null);

  const allReservationsData = useSelector(allReservations);
  const previousReservations: any = useSelector(previousReservationsSelector);
  const previousHighlights: any = useSelector(previousHighlightsSelector);
  const upcomingReservations: any = useSelector(upcomingReservationsSelector);
  const upcomingHighlights: any = useSelector(upcomingHighlightsSelector);
  const isMobileView = useSelector(isMobileModeSelector);

  const indicators: any = useSelector(reservationsIndicatorsSelector());
  const indicatorsPrevious: any = useSelector(reservationsPreviousIndicatorsSelector());
  const [requestedIndicators, setRequestedIndicators] = useState({})


// Memoize the nested property to stabilize its reference
const memoizedUpcomingReservations = useMemo(() => 
  indicators?.indicators?.Reservations?.UpcomingPaidReservations, 
  [indicators]
);

useEffect(() => {
  if (!memoizedUpcomingReservations && active === 'Upcoming') {
    dispatch({
      type: RESERVATIONS_INDICATORS_REQUEST,
    });
  }
}, [dispatch, active, memoizedUpcomingReservations]);



  let reservations: any;
  let highlights: any;

  if (startDate && endDate) {
    reservations =
      active === 'Previous'
        ? filteredReservations?.current.previous
        : filteredReservations?.current.upcoming;
    highlights =
      active === 'Previous'
        ? filteredReservations?.current.previousHighlightsData
        : filteredReservations?.current.upcomingHighlightsData;
  } else {
    reservations = active === 'Previous' ? previousReservations : upcomingReservations;
    highlights = active === 'Previous' ? previousHighlights : upcomingHighlights;
  }

  const filterTable = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
    filteredReservations.current = filterReservationsByDateRange(allReservationsData, start, end)
  };

  useEffect(() => {
    if (!previousReservations || !upcomingReservations) {
      dispatch({
        type: GET_RESERVATIONS_DATA_REQUEST,
      });
    }
  }, [dispatch, previousReservations, upcomingReservations]);

  // it called infinite time to api endpoints that's why we commented this code now this section is called in the very top of the element 
  // useEffect(() => {
  //   if (!indicators?.indicators?.Reservations?.UpcomingPaidReservations && active === 'Upcoming') {
  //     dispatch({
  //       type: RESERVATIONS_INDICATORS_REQUEST,
  //     });
  //   }
  // }, [dispatch, indicators, active]);  

  useEffect(() => {
    if (!indicatorsPrevious?.indicators?.Reservations?.PreviousPaidReservations && active === 'Previous') {
      dispatch({
        type: RESERVATIONS_PREVIOUS_INDICATORS_REQUEST,
      });
    }
  }, [dispatch, indicatorsPrevious, active]);

useEffect(() => {
  if (active === 'Upcoming') {
    setRequestedIndicators({
      GuestsHosted: indicators?.indicators?.GuestsHosted?.UpcomingGuestHosted,
      NightsBooked: indicators?.indicators?.NightsBooked?.UpcomingNightBooked,
      OwnersBlocks: indicators?.indicators?.OwnersBlocks?.UpcomingOwnerBlocks,
      Reservations: indicators?.indicators?.Reservations?.UpcomingPaidReservations
    })
  } else if (active === 'Previous') {
    setRequestedIndicators({
      GuestsHosted: indicatorsPrevious?.indicators?.GuestsHosted?.PreviousGuestsHosted,
      NightsBooked: indicatorsPrevious?.indicators?.NightsBooked?.PreviousNightsBooked,
      OwnersBlocks: indicatorsPrevious?.indicators?.OwnersBlocks?.PreviousOwnerBlocks,
      Reservations: indicatorsPrevious?.indicators?.Reservations?.PreviousPaidReservations
    })
  }
}, [active, indicators, indicatorsPrevious])

  return (
    <div className={classes.tableContainer}>
      <div className={classes.header}>
        <div className={classes.navigation}>
          <Link className={classes.navLink} to="/calendar">
            <LeftChevron/>
            <h4>Reservations</h4>
          </Link>
        </div>
        <div className={classes.controls}>
          {!isMobileView && (
            <SwitchControl
              active={active}
              handleSwitch={setActive}
              left={'Previous'}
              right={'Upcoming'}
            />
          )}
          <SelectDates filterTable={filterTable} />
        </div>
      </div>

      <CalendarReservationsHighlights data={{ ...highlights, ...requestedIndicators }} />
      {isMobileView && (
        <div style={{paddingBottom: 16}}>
          <SwitchControl
            active={active}
            handleSwitch={setActive}
            left={'Previous'}
            right={'Upcoming'}
          />
        </div>
      )}
      <Table className={classes.reservationsTable} aria-label="Revenue table">
        <TableHead className={classes.tableHead}>
          {!isMobileView ? (
            <TableRow>
              <TableCell align="center">Dates</TableCell>
              <TableCell align="center">Nights</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="center">Guests</TableCell>
              <TableCell align="center">NRI</TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell align="center">Dates</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">NRI</TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody className={classes.tableBody}>
          {reservations &&
            (active === 'Previous' ? Object.keys(reservations).reverse() : Object.keys(reservations)).map((yearKey: any) => {
              const yearData: any = reservations[yearKey];
              const monthKeys = active === 'Previous' ? Object.keys(yearData).reverse() : Object.keys(yearData);

              return monthKeys.map((monthKey: any) => {
                const shortMonthName = shortMonthsNames[monthKey];
                const fullMonthName = longMonthsNames[monthKey];
                const monthData: any = yearData[monthKey];
                const daysData: IReservation[] = Object.values(monthData);
                const processedDaysData = active === 'Previous' ? daysData.sort((a: IReservation, b: IReservation) => new Date(b.checkin_at).valueOf() - new Date(a.checkin_at).valueOf()) : daysData;
                let monthIncome = 0;

                return (
                  <React.Fragment key={`${yearKey}_${monthKey}_month`}>
                    <TableRow className={classes.tableRow}>
                      <TableCell
                        align="left"
                        className={`${classes.blueBold} ${classes.outerDate}`}
                      >{`${fullMonthName} ${yearKey}`}</TableCell>
                    </TableRow>
                    {processedDaysData.map(
                      (
                        {
                          checkin_at,
                          checkout_at,
                          created_by,
                          guest_name,
                          guest_count,
                          nights_count,
                          netincome,
                        }: IReservation,
                        index: number
                      ) => {
                        const checkinDay = new Date(checkin_at).getDate();
                        const checkoutDay = new Date(checkout_at).getDate();
                        monthIncome += Number(netincome) || 0;

                        const type = netincome ? 'Paid Reservation' : 'Owner Block';

                        return !isMobileView ? (
                          <TableRow
                            className={classes.tableRow}
                            key={`${yearKey}_${monthKey}_${index}_day`}
                          >
                            <TableCell
                              align="center"
                              style={{display: 'flex', flexDirection: 'row'}}
                            >
                              <div className={classes.innerDateBlock}>
                                <p className={`${classes.blueBold} ${classes.shortMonthName}`}>
                                  {shortMonthName}
                                </p>
                                <p>
                                  {checkinDay} - {checkoutDay}
                                </p>
                              </div>
                              <Divider
                                className={
                                  netincome ? classes.paid : classes.owner
                                }
                                flexItem
                                orientation="vertical"
                              />
                            </TableCell>
                            <TableCell align="center">
                              {showLabel(nights_count, 'night', 'nights')}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={
                                netincome ? classes.greenBold : classes.blueBold
                              }
                            >
                              {type}
                            </TableCell>
                            <TableCell align="left">
                              {guest_name || created_by || '-'}
                            </TableCell>
                            <TableCell align="center">
                              {showLabel(guest_count, 'guest', 'guests')}
                            </TableCell>
                            <TableCell align="center">
                              {netincome
                                ? `$${toPrettyNumber(netincome)}`
                                : '-'}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow
                            className={classes.tableRow}
                            key={`${yearKey}_${monthKey}_${index}_day`}
                          >
                            <TableCell
                              align="left"
                              style={{display: 'flex', flexDirection: 'row'}}
                            >
                              <div className={classes.innerDateBlock}>
                                <p
                                  className={`${classes.blueBold} ${classes.shortMonthName}`}
                                >
                                  {shortMonthName}
                                </p>
                                <p className={classes.dates}>
                                  {checkinDay} - {checkoutDay}
                                </p>
                                <p className={classes.nights}>
                                  {showLabel(nights_count, 'night', 'nights')}
                                </p>
                              </div>
                              <Divider
                                className={
                                  netincome ? classes.paid : classes.owner
                                }
                                flexItem
                                orientation="vertical"
                              />
                            </TableCell>
                            <TableCell align="left">
                              <p
                                className={
                                  netincome
                                    ? classes.greenBold
                                    : classes.blueBold
                                }
                              >
                                {type}
                              </p>
                              <p className={classes.name}>
                                {guest_name || created_by || '-'}
                              </p>
                              <p className={classes.guests}>
                                {showLabel(guest_count, 'guest', 'guests')}
                              </p>
                            </TableCell>
                            <TableCell align="center">
                              <p className={classes.netIncome}>
                                {netincome
                                  ? `$${toPrettyNumber(netincome)}`
                                  : '-'}
                              </p>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                    <TableRow className={classes.tableRow}>
                      <TableCell
                        align="right"
                        className={`${classes.blueBold} ${classes.total}`}
                        colSpan={6}
                      >{`Total: $${toPrettyNumber(monthIncome)}`}</TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              });
            })}
        </TableBody>
      </Table>
    </div>
  );
};
