import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./OccupancyRatePie.module.scss";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { periodList } from "../../../../../utils/constants/periodLists";
import { PeriodDropdown } from "../../../../common/PeriodDropdown/PeriodDropdown";
import { Legend } from "../../../../common/ChartLegend/ChartLegend";
import { RESERVATIONS_VS_BLOCKS_OCCUPANCY_REQUEST } from "../../../../../redux/actions";
import {
  OCCUPANCY_PIE_COLORS,
  RADIAN
} from "../../../../../utils/constants/chartConstants";
import { Divider } from "@mui/material";
import { BasicTooltip } from "../../../../common/BasicTooltip/BasicTooltip";
import { occupancyPieOwnerVsPaidReserv } from "../../../../../utils/constants/tooltipTexts";
import { L12M, L30D } from "../../../../../utils/constants/filterConstants";
import { reservationsVsBlocksSelector } from "../../../../../redux/selectors/occupancyRateSelector";
import {isNumber, toDoublePercent, toWholePercent} from "../../../../../utils/commonUtils";
import { Toast } from "../../../../common/Toast/Toast";
import {isMobileModeSelector} from "../../../../../redux/selectors/appStatusSelector";

const occupancyPieLegend = [
  {
    label: "Paid Reservations",
    color: "#00B48D",
    legendType: "circle"
  },
  {
    label: "Owner Blocks",
    color: "#5D6293",
    legendType: "circle"
  }
];

export const OccupancyRatePie = () => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState(L30D);
  const reservationsData = useSelector(reservationsVsBlocksSelector(period));
  const isMobileView = useSelector(isMobileModeSelector);
  const { ownerBlocks, paidReservations } = reservationsData || {};

  const ownerBlocksRatio = ownerBlocks > 1 ? ownerBlocks / 100 : ownerBlocks;
  const paidReservationsRatio = paidReservations > 1 ? paidReservations / 100 : paidReservations;

  const total = ownerBlocksRatio + paidReservationsRatio;

  const data = [
    { name: "Owner Blocks", value: ownerBlocksRatio },
    { name: "Paid Reservations", value: paidReservationsRatio },
    { name: "empty", value: 1 - ownerBlocksRatio - paidReservationsRatio }
  ];

  useEffect(() => {
    dispatch({
      type: RESERVATIONS_VS_BLOCKS_OCCUPANCY_REQUEST,
      payload: {
        param: period
      }
    });
  }, [dispatch, period]);

  const renderCustomizedLabel = useCallback(
    ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name }: any) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      if (name === 'empty' || percent === 0) return null;

      return (
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
        >
          {`${toWholePercent(percent)}%`}
        </text>
      );
    },
    []
  );

  return (
    <div className={classes.occupancyRateContainer}>
      <div className={classes.occupancyRateHeader}>
        <div className={classes.headerWithTooltip}>
          <h4>Occupancy Rate</h4>
          <BasicTooltip tooltip={occupancyPieOwnerVsPaidReserv} />
        </div>
        <PeriodDropdown
          className={classes.dropdown}
          period={period}
          periodList={periodList}
          setPeriod={setPeriod}
        />
      </div>
      {!isMobileView ? <Divider /> : null}
      <div className={classes.pieContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={214} height={214}>
            <Pie
              cx={"50%"}
              cy={"50%"}
              data={data}
              dataKey="value"
              fill="#8884d8"
              startAngle={90}
              endAngle={-270}
              labelLine={false}
              label={renderCustomizedLabel}
              innerRadius={66}
              outerRadius={110}
              animationDuration={250}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={OCCUPANCY_PIE_COLORS[index]}
                  stroke="none"
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        {isNumber(total) ? (
          <h2 className={classes.total}>{`${(100 * total).toFixed(1)}%`}</h2>
        ) : (
          <Toast className={classes.toast} type="info" />
        )}
      </div>
      <Legend className={classes.legend} legendData={occupancyPieLegend} />
    </div>
  );
};
