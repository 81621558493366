import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./TotalRevenueCard.module.scss";
import { GET_REVENUE_REQUEST } from "../../../../../../redux/actions";
import { perfomanceHighlightsSelector } from "../../../../../../redux/selectors/perfomanceHighlightsSelector";
import { totalRevenueSelector } from "../../../../../../redux/selectors/revenueSelector";
import { L12M, L3060D, N30D, L30D } from '../../../../../../utils/constants/filterConstants';
import { periodList } from "../../../../../../utils/constants/periodLists";
import { Highlight } from "../../../../../common/BasicHighlight/BasicHighlight";
import { PeriodDropdown } from "../../../../../common/PeriodDropdown/PeriodDropdown";
import { Divider } from "@mui/material";
import {isMobileModeSelector} from "../../../../../../redux/selectors/appStatusSelector";

export const TotalRevenueCard = () => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState(L30D);
  const previousPeriod = period === N30D ? L12M : L3060D;

  const data = useSelector(perfomanceHighlightsSelector(period));
  const previousData = useSelector(perfomanceHighlightsSelector(previousPeriod));

  const { netReservationIncome } = data || {};
  const { netReservationIncome: previousNetReservationIncome } = previousData || {};

  const totalRevenue = useSelector(totalRevenueSelector);
  const isMobileView = useSelector(isMobileModeSelector);

  useEffect(() => {
    dispatch({
      type: GET_REVENUE_REQUEST,
      payload: { param: period }
    });
  }, [dispatch, period]);

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardHeader}>
        <h4>Revenue</h4>
        <PeriodDropdown
          className={classes.dropdown}
          period={period}
          periodList={periodList}
          setPeriod={setPeriod}
        />
      </div>
      <div className={classes.cardBody}>
        {netReservationIncome && (
          <Highlight
            className={classes.highlight}
            label="Net Reservation Income:"
            prependSymbol={"$"}
            reverse
            value={netReservationIncome}
            previousValue={previousNetReservationIncome}
            showDynamicIndicator={period !== L12M && period !== N30D}
          />
        )}
        {!isMobileView ? <Divider orientation="vertical" /> : null}
        {totalRevenue && (
          <Highlight
            className={classes.highlight}
            changeValueColor
            label="All Time Income:"
            prependSymbol={"$"}
            reverse
            value={totalRevenue}
            showDynamicIndicator={false}
          />
        )}
      </div>
    </div>
  );
};
