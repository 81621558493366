import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import classes from "./OccupancyRateVsMarket.module.scss";
import {
  GENERAL_OCCUPANCY_REQUEST,
  MARKET_OCCUPANCY_REQUEST
} from "../../../../../redux/actions";
import {
  generalOccupancySelector,
  marketOccupancySelector
} from "../../../../../redux/selectors/occupancyRateSelector";
import { isNumber } from "../../../../../utils/commonUtils";
import { L12M, L3060D, N30D, L30D } from '../../../../../utils/constants/filterConstants';
import { periodList } from "../../../../../utils/constants/periodLists";
import { OccupancyHighlight } from "../../../../common/CircleOccupancyHighlight/CircleOccupancyHighlight";
import { PeriodDropdown } from "../../../../common/PeriodDropdown/PeriodDropdown";
import { DynamicIndicator } from "../../../../common/DynamicIndicator/DynamicIndicator";
import {isMobileModeSelector} from "../../../../../redux/selectors/appStatusSelector";

export const OccupancyRateVsMarket = () => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState(L30D);
  const generalOccupancy = useSelector(generalOccupancySelector(period));
  const { MarketOccupancy, market_or_l30_60_day } = useSelector(marketOccupancySelector(period)) || {};

  const previousPeriod = period === N30D ? L30D : L3060D;
  const previousGeneralOccupancy = useSelector(generalOccupancySelector(previousPeriod));

  const isMobile = useSelector(isMobileModeSelector);

  useEffect(() => {
    dispatch({
      type: GENERAL_OCCUPANCY_REQUEST,
      payload: {
        param: period
      }
    });
    dispatch({
      type: MARKET_OCCUPANCY_REQUEST,
      payload: {
        param: period
      }
    });
  }, [dispatch, period]);

  return (
    <div className={classes.occupancyRateContainer}>
      <div className={classes.occupancyRateHeader}>
        <h4>{isMobile ? 'OR vs Market OR' : 'Occupancy Rate vs Market'}</h4>
        <PeriodDropdown
          className={classes.dropdown}
          period={period}
          periodList={periodList}
          setPeriod={setPeriod}
        />
      </div>
      {!isMobile ? <Divider /> : null}
      <div className={classes.occupancyRate}>
        <div>
          <p>Occupancy Rate</p>
          {period !== L12M && (
            <DynamicIndicator previous={generalOccupancy?.last30_60_days} current={generalOccupancy?.total} />
          )}
        </div>
        {isNumber(generalOccupancy?.total) && (
          <OccupancyHighlight
            rate={generalOccupancy?.total}
          />
        )}
      </div>
      <div className={classes.occupancyRate}>
        <div>
          <p>Market Occupancy Rate</p>
          {period !== L12M && (
            <DynamicIndicator previous={market_or_l30_60_day} current={MarketOccupancy} />
          )}
        </div>
        {isNumber(MarketOccupancy) && (
          <OccupancyHighlight rate={MarketOccupancy} highlightColor="#EDC255" />
        )}
      </div>
    </div>
  );
};
