import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, BarChart, XAxis, YAxis, ResponsiveContainer, Tooltip } from "recharts";
import { Divider } from "@mui/material";
import { PeriodDropdown } from "../../../../common/PeriodDropdown/PeriodDropdown";
import { Legend } from "../../../../common/ChartLegend/ChartLegend";
import { getDayName, periodList } from "../../../../../utils/constants/periodLists";
import { L12M, L30D } from "../../../../../utils/constants/filterConstants";
import { DAILY_OCCUPANCY_REQUEST } from "../../../../../redux/actions";
import { dailyOccupancySelector } from "../../../../../redux/selectors/occupancyRateSelector";
import {isMobileModeSelector} from "../../../../../redux/selectors/appStatusSelector";
import classes from "./DailyOccupancyChart.module.scss";

const CustomTooltip = (props: any) => {
  const {
    active,
    payload,
    viewBox,
    isMobileView,
    barWidth,
    activeTooltipIndex,
    leftTooltipRef
  } = props;

  if (active && payload && payload?.length) {
    const leftDataPoint = payload[0].payload;
    const leftName = payload[0].name;

    return (
      <>
        <div
          className={`${classes.tooltip} ${classes.rightTooltip}`}
          style={{
            bottom: isMobileView ?
              -barWidth * 3 - (viewBox.y + barWidth / 2) - (barWidth * (activeTooltipIndex || 0)) * ((activeTooltipIndex === 0) ? 1 : 2) + barWidth - (activeTooltipIndex || 0) / 2 + barWidth / 2 - (activeTooltipIndex || 0) * 4 + 3 :
              -barWidth * 3 - (viewBox.y + barWidth / 2) - (barWidth * (activeTooltipIndex || 0)) * ((activeTooltipIndex === 0) ? 1 : 2) + barWidth - (activeTooltipIndex || 0) / 2 + barWidth / 2 - 1,
            left: viewBox.width / 2
          }}
          ref={leftTooltipRef}
        >
          <div className={`${classes.box} ${classes.green}`}></div>
          <p>{`${getDayName(payload[0].payload.Day_of_week)}: ${leftDataPoint[leftName]}%`}</p>
        </div>
      </>
    );
  }

  return null;
};

export const DailyOccupancyChart = () => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState(L30D);
  const data = useSelector(dailyOccupancySelector(period));
  const isMobileView = useSelector(isMobileModeSelector);

  const occupancyBarLegend = [
    {
      label: "Occupancy Rate",
      color: "#00B48D",
      legendType: "circle"
    }
  ];

  useEffect(() => {
    dispatch({
      type: DAILY_OCCUPANCY_REQUEST,
      payload: {
        param: period
      }
    });
  }, [dispatch, period]);

  const barWidth = 16;
  const responsiveContainerRef = useRef<any>(null);
  const leftTooltipRef = useRef<any>(null);

  const [activeTooltipIndex, setActiveTooltipIndex] = useState<
    number | undefined
  >();

  return (
    <div className={classes.occupancyChartContainer}>
      <div className={classes.occupancyChartHeader}>
        <div className={classes.occupancyLegendContainer}>
          <h4>{isMobileView ? "OR by Day" : "Occupancy Rate by Day"}</h4>
          {!isMobileView ? <Divider flexItem orientation="vertical"/> : null}
          {!isMobileView ? <Legend legendData={occupancyBarLegend}/> : null}
        </div>
        <PeriodDropdown
          className={classes.dropdown}
          period={period}
          periodList={periodList}
          setPeriod={setPeriod}
        />
      </div>
      {data && (
        <ResponsiveContainer width="100%" height="80%" ref={responsiveContainerRef}>
          <BarChart
            layout="vertical"
            data={data}
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
            onMouseMove={({ activeTooltipIndex }) =>
              setActiveTooltipIndex(activeTooltipIndex)
            }
            onMouseLeave={() => setActiveTooltipIndex(undefined)}
          >
            <XAxis
              axisLine={false}
              tickLine={false}
              type="number"
              unit="%"
            />
            <YAxis
              axisLine={false}
              dataKey="Day_of_week"
              tickLine={false}
              type="category"
              width={isMobileView ? 44 : 60}
            />
            <Tooltip
              position={{
                x: 0,
                y: 0
              }}
              content={<CustomTooltip
                isMobileView={isMobileView}
                barWidth={barWidth}
                activeTooltipIndex={activeTooltipIndex}
                leftTooltipRef={leftTooltipRef}
              />}
              cursor={false}
              allowEscapeViewBox={{ x: true, y: true }}
            />
            <Bar
              barSize={16}
              dataKey="occupancy_rate"
              isAnimationActive={false}
              fill="#00B48D"
              radius={[0, 4, 4, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
      {isMobileView ? <Legend legendData={occupancyBarLegend}/> : null}
    </div>
  );
};
